import { Injectable } from '@angular/core';
import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';

interface State {
  activeTaskId: string | null;
}

const defaultState: State = {
  activeTaskId: null,
};

@Injectable({
  providedIn: 'root',
})
export class UrlParamsState {
  private readonly urlParamsState = signalState<State>(defaultState);

  public readonly activeTaskId = this.urlParamsState.activeTaskId;

  setActiveTaskId(activeTaskId: string | null) {
    patchState(this.urlParamsState, this.activeTaskIdSetter(activeTaskId));
  }

  private activeTaskIdSetter(
    activeTaskId: string | null
  ): PartialStateUpdater<{ activeTaskId: string | null }> {
    return () => ({ activeTaskId });
  }
}
